<template>
  <div class="misc-wrapper">
    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center mb-4">
        <b-img
          fluid
          :src="`${portal}assets/images/logo/gradient-logo.png`"
          alt="Unauthenticated Page"
        />
      </div>
      <div class="w-100 text-center p-relative">
        <h1 class="mb-1 text-uppercase">
          <strong>Not Logged In</strong>
        </h1>
        <p class="mb-2">
          It seems like you are not logged in or your session has already expired.<br>Please login to proceed to the dashboard.
        </p>
        <b-button
          variant="primary"
          class="mb-1 btn-sm-block"
          :href="`${portal}login`"
        >Login
        </b-button>
        <b-img
          fluid
          :src="imgUrl"
          alt="Unauthenticated Page"
          class="bg-img"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BImg, BButton } from 'bootstrap-vue'
import store from '@/store/index'

export default {
  components: {
    BImg, BButton,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/not-authorized.svg'),
      portal: process.env.VUE_APP_PORTAL,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/not-authorized-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>

<style lang="scss" scoped>
.bg-img {
  position: absolute;
  top: -8rem;
  left: -4rem;
  z-index: -1;
  opacity: 0.09;
}
</style>
